; (function () {
  'use strict';

  var $nv = $('#top-nav');
  var $bc = $('#breadcrumb');
  var $mn = $('#main');
  var $sh = $(".sharing-card");

  function getVH() {
    return 100 / document.documentElement.clientHeight;
  }

  function getBottom(elem) {
    if (elem.length > 0) {
      var bottom = elem.position().top - $(window).scrollTop() + elem.outerHeight(true);
      bottom = getVH() * bottom;
      return bottom;
    } else {
      return 0;
    }
  }

  function setTop() {
    var bottomNV = getBottom($nv);
    var bottomBC = getBottom($bc);
    var bottomMN = getBottom($mn);
    var bottomSH = getVH() * $sh.outerHeight(true);
    var tv = 39;

    if (bottomNV > 27 && bottomBC > 0) {
      tv = bottomNV + 30;
    } else if (bottomNV > 34) {
      tv = bottomNV + 10;
    } else if (bottomMN < bottomSH + 39) {
      tv = bottomMN - bottomSH;
    }

    if (tv < bottomNV) tv = 39;

    $sh.css({ top: tv + "vh" });
  }

  $(window).on("resize scroll", function () {
    setTop();
  });

  $(document).ajaxSuccess(function () {
      setTop();
  });

}());
