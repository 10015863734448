(function () {
  function addToggle() {
    var isDesktop = Boolean($(window).width() > 767);
    var $navToggle = $('#nav-toggle');
    var $pills = $('#pills');

    if (isDesktop) {
      $navToggle.removeAttr('data-toggle').removeClass('collapsed');
      $pills.addClass('collapse in');
    } else {
      $navToggle.attr('data-toggle', 'collapse').addClass('collapsed');
      $pills.removeClass('in').addClass('collapse');
    }

    $navToggle.attr('aria-expanded', isDesktop.toString());
    $pills.attr('aria-expanded', isDesktop.toString());
  }
  $(window).on('ready load resize', addToggle);
})();
