var body = $("body");

//On click of modal launch button, send "this" to show modal function
$( document ).on( "click", ".modal-launch", function ( e ) {
    e.preventDefault();
    showModal($(this));
});

//On click of click-to-close buttons, send "this" to hide modal function
$(document).on("click", ".click-to-close", function (e) {
    e.preventDefault();
    var modalToClose = $(this).parents(".cfa-modal");
    hideModal(modalToClose);
});

//Function to get all foucsable elements 
function getFocusableElements(container) {
    var focusableElements = container.find($("a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabindex], *[contenteditable], .focusable").not("[tabindex=-1], [disabled], :hidden, #skip-link, #main")); //avoiding skip link and main for now
    return focusableElements;
}

//Function to get all tabindex=-1 elements in order to remove that attribute (related to open/closing of modals and focus trapping) 
function getNegativeOneTabindex(container) {
    var negativeOneTabindex = container.find($("[tabindex=-1]").not("#skip-link, #main")); //avoiding skip link and main for now
    return negativeOneTabindex;
}

//On document click, if modal is open, call function to keep focus inside that modal
$(document).on("click", function (e) {
    $modal = $('.cfa-modal:visible');
    if ($modal.length && !$modal.contents().filter(el => e.target).length) {
        keepFocusInModal($modal)
    }
});

////Prevent the above document click from working on the modal
$(document).on("click", ".cfa-modal", function (e) {
    e.stopPropagation();
});

//On document keydown, if key is escape key and modal is visible, call function to close modal on escape keydown 
//OR
//On document keydown, if key is tab key and modal is visible, call function to trap focus inside modal 
$(document).on("keydown", function (e) {
    if (e.which == 27 && $(".cfa-modal.md-modal").is(":visible")) {
        e.preventDefault();
        $(".cancel").trigger('focus');
    } else if (e.which == 27 && $(".cfa-modal").is(":visible")) {
        closeOnEscape($(".cfa-modal").filter(":visible"));
    } else if (e.which == 9 && $(".cfa-modal").is(":visible")) {
        trapTab($(".cfa-modal").filter(":visible"), e);
    }
});


//Function to handle escape key press for modal
function closeOnEscape(currentVisibleModal) {
    //var visibleModalClose = currentVisibleModal.find($(".modal-close"));
    var visibleModalClose = currentVisibleModal;
    hideModal(visibleModalClose);
}

//Function to send focus back to modal's first focusable element if user clicks on body while modal is open
function keepFocusInModal(currentVisibleModal) {
    currentVisibleModal.find("[tabindex=0]").first().trigger('focus');
}

//Function to trap tab inside modal
function trapTab(currentModal, e) {
    //Get list of focusable items within the modal
    var modalFocusChildren = getFocusableElements(currentModal).filter(':visible');
    //Get currently focused item
    var focusedItem = $(":focus");
    //Get the number of focusable items
    var numberOfModalFocusChildren = modalFocusChildren.length;
    //Get the index of the currently focused item
    var modalFocusChildrenIndex = modalFocusChildren.index(focusedItem);

    //If shift tab (back tabbing)
    if (e && e.shiftKey) {
        //If focused on first item and user preses back-tab, go to the last focusable item
        if (modalFocusChildrenIndex === 0) {
            modalFocusChildren.eq(numberOfModalFocusChildren - 1).trigger('focus');
            e.preventDefault();
        }
    } else {
        //Forward tab and default focus
        if (
            modalFocusChildrenIndex === -1 ||
            (modalFocusChildrenIndex === numberOfModalFocusChildren - 1)
        ) {
            //If focused on the last item and user preses tab, go to the first focusable item
            modalFocusChildren.first().trigger('focus');
            e && e.preventDefault();
        }
    }
}

//Function to show modal
function showModal(modalLaunchButton) {
    //Set variables based on data passed into function
    var modalLaunchButtonId = modalLaunchButton.data("target");
    var modalToLaunch = $(modalLaunchButtonId);

    //Check to make sure modal(s) exist before executing (this can help prevent focusing/tabing/etc issues from happening and breaking the page for users
    if ( modalToLaunch.length > 0 ) {
        //Positioning body as fixed so user cannot scroll while modal is open
        $("body").css("overflow", "hidden");

        //Setting tabindex to -1 on focusable elements outside of the modal so screen reader (and keyboard) can't access them
        getFocusableElements(body).attr("tabindex", -1);

        //Create and append modal backdrop div to page
        $('<div class="modal-backdrop"></div>').appendTo(body);

        //Change aria-expanded attribute of button that launched modal to true so screen reader announces modal has launched
        modalLaunchButton.attr("aria-expanded", "true");

        //Add "open" class to modal to trigger css to make modal visible
        modalToLaunch.addClass("open");

        //Make modal accessible to screen readers
        modalToLaunch.attr("aria-hidden", "false");
    }

    return;
}

//Function to hide modal
function hideModal(modalCloseButton) {
    //Set variables based on data passed into function
    //var modalToClose = modalCloseButton.parents(".cfa-modal");
    //var modalToCloseID = "#" + modalToClose.attr('id');
    var modalToCloseID = "#" + modalCloseButton.attr('id');

    //var modalToCloseLaunchButton = $('[data-target="' + modalToCloseID + '"]');
    var modalToCloseLaunchButton = $('[data-target="' + modalToCloseID + '"]');

    //Remove fixed position from body
    $("body").css("overflow", "");

    //Removing tabindex attribute on elements outside of the modal so screen reader (and keyboard) can access them again
    getNegativeOneTabindex(body).removeAttr("tabindex");

    //Remove the modal backdrop
    $(".modal-backdrop").remove();

    //Remove "open" class to modal to remove modal visibility
    //modalToClose.removeClass("open");
    modalCloseButton.removeClass("open");

    //Make modal inaccessible to screen readers
    //modalToClose.attr("aria-hidden", "true");
    modalCloseButton.attr("aria-hidden", "true");

    //Change aria-expanded attribute of button that launched modal to false so screen reader announces modal is hidden/collapsed
    modalToCloseLaunchButton.attr("aria-expanded", "false");

    //Set focus back to button that launched the modal
    modalToCloseLaunchButton.trigger('focus');
}
