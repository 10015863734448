; (function () {
    'use strict';
    var masthead = $(".masthead");
    var searchContainer = $(".search-container");
    var searchButton = $(".search-button");
    var menuButton = $("#menu-button");
    var navContainer = $(".nav-container");
    var primaryNavList = $(".primary-nav-list");
    var primaryNavListItem = $(".primary-nav-list-item");
    var primaryNavListItemExpand = $(".primary-nav-list-item-expand");
    var secondaryNav = $(".secondary-nav");
    var secondaryNavList = $(".secondary-nav-list");
    var secondaryNavListItem = $(".secondary-nav-list-item");
    var secondaryNavListItemExpand = $(".secondary-nav-list-item-expand");
    var tertiaryNavList = $(".tertiary-nav-list");
    var main = $("#main");
    var returnToLink = $(".return-to-site");

    var primaryHasDropdown = secondaryNav.prev(primaryNavListItem);
    var secondaryHasDropdown = tertiaryNavList.prev(secondaryNavListItem);

    var mQSmall = window.matchMedia("screen and (min-width: 768px)");
    var mQMedium = window.matchMedia("screen and (min-width: 992px)");
    var mQLarge = window.matchMedia("screen and (min-width: 1200px)");
    var mQLargeAlt = window.matchMedia("screen and (min-width: 1209px)");



    //When page loads and JS is available, give masthead and primary nav class "js" and set Main's margin-top.
    masthead.addClass("js");
    primaryNavList.addClass("js");
    //setMarginTop(isMobileWidth(), main);
    setMarginTopForLinkOrMain();



    /////////////////////////// CFA Institute Store masthead code ///////////////////////////
    var cartMasthead = $("#cartMasthead");
    var cartMenuButton = $("#cartMenuButton");
    var primaryNavListCart = $("#primaryNavListCart");

    //Add JS class to cart masthead if JS is enabled
    cartMasthead.addClass("js");

    //On cart menu button click, toggle display of cart's mobile nav
    cartMenuButton.on("click", function (e) {
        e.stopPropagation();
        show($(this), primaryNavListCart);
    });

    //function to show a section of code and adjust aria expanded attribute --- would love to use this to replace some other functions in here at some point in time
    function show(button, container) {
        if (button.attr("aria-expanded") === "false") {
            button.attr("aria-expanded", true);
        } else {
            button.attr("aria-expanded", false);
        }

        container.toggleClass("open");
    }

    //function to hide a section of code and adjust aria expanded attribute --- would love to use this to replace some other functions in here at some point in time
    function hide(button, container) {
        button.attr("aria-expanded", false);
        container.removeClass("open");
    }
    /////////////////////////// CFA Institute Store masthead code ///////////////////////////


    //Function to handle current state of return to site link and hopefully improve this when/if app nav comes back into play (GIPS app nav?)
    function setMarginTopForLinkOrMain() {
        if (returnToLink.length > 0) {
            if (returnToLink.css("display") === "block") {
                setMarginTop(!mQLargeAlt.matches, returnToLink);
                main.css("margin-top", "");
            } else {
                setMarginTop(!mQLargeAlt.matches, main);
            }
        } else {
            setMarginTop(!mQLargeAlt.matches, main);
        }
    }

    //On page load run function to add/remove aria attributes
    ariaAttributes();

    //Get masthead height.
    function getMastheadHeight() {
        var mastheadHeight = masthead.outerHeight();
        return mastheadHeight;
    }

    //Function setting position top
    function setPositionTop(positionItem, measurement) {
        if (positionItem != secondaryNav) {
            positionItem.css("top", measurement.outerHeight());
        } else {
            positionItem.css("top", measurement.outerHeight() - 1);
        }
    }

    //Function to set or remove element's margin-top
    function setMarginTop(condition, element) {
        setTimeout(function() {
            if (condition) {
                element.css("margin-top", getMastheadHeight());
            } else {
                element.css("margin-top", "");
            }
        }, 0);
    }

    //Function to handle positioning of secondary nav dropdown on desktop view
    function secondaryNavPositioning(primaryNavLinkClicked) {
        //Set variables to handle placement of secondary nav
        var windowInnerWidth = $(window).innerWidth();
        var windowXCenter = windowInnerWidth / 2;
        var windowXOneFourth = windowXCenter / 2;
        var windowXThreeFourth = windowXCenter + windowXOneFourth

        secondaryNav.removeClass("one-quarter two-quarter three-quarter four-quarter");

        if (mQLargeAlt.matches) {
            // if NLI is right of 3/4ths of page
            //if (primaryNavLinkClicked.offset().left >= windowXThreeFourth) {
            var primaryNavLinkClickedCenter = primaryNavLinkClicked.offset().left + primaryNavLinkClicked.width() / 2;
            if (primaryNavLinkClickedCenter >= windowXThreeFourth) {
                primaryNavLinkClicked.siblings(".secondary-nav").addClass("four-quarter");
            }
            // if NLI is right of center, but left of 3/4ths of page
            //else if (primaryNavLinkClicked.offset().left >= windowXCenter &&
            else if (primaryNavLinkClickedCenter >= windowXCenter && primaryNavLinkClickedCenter <= windowXThreeFourth) {
                primaryNavLinkClicked.siblings(".secondary-nav").addClass("three-quarter");
            }
            // if NLI is left of 1/4ths of page
            //else if (primaryNavLinkClicked.offset().left < windowXOneFourth) {
            else if (primaryNavLinkClickedCenter < windowXOneFourth) {
                primaryNavLinkClicked.siblings(".secondary-nav").addClass("one-quarter");
            }
            // if NLI is left of center, but right of 1/4ths of page
            //else if (primaryNavLinkClicked.offset().left <= windowXCenter &&
            else if (primaryNavLinkClickedCenter <= windowXCenter && primaryNavLinkClickedCenter >= windowXOneFourth) {
                primaryNavLinkClicked.siblings(".secondary-nav").addClass("two-quarter");
            }
        }
    }

    //Function to hide all navigation items.
    function HideAllNav() {
        //searchButton.attr("aria-expanded", "false");
        //searchContainer.removeClass("open").removeAttr("style");
        menuButton.attr("aria-expanded", "false");
        primaryNavList.removeClass("open").removeAttr("style");
        primaryNavListItem.removeClass("open");
        primaryNavListItemExpand.attr("aria-expanded", "false");
        secondaryNav.removeClass("one-quarter two-quarter three-quarter four-quarter").removeAttr("style");
        secondaryNavListItem.removeClass("open");
        secondaryNavListItemExpand.attr("aria-expanded", "false");
    }

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var mastheadHeight = masthead.outerHeight();

    $(window).on("scroll", function (e) {
        if (!mQLargeAlt.matches) {
            didScroll = true;
        }
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(window).scrollTop();

        // Make sure they scroll more than delta, or that menu isn't already open
        //if (Math.abs(lastScrollTop - st) <= delta || mainNav.hasClass("open"))
        if (Math.abs(lastScrollTop - st) <= delta || primaryNavList.hasClass("open"))
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > mastheadHeight) {
            // Scroll Down
            masthead.addClass('nav-up');
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                masthead.removeClass('nav-up');
            }
        }

        lastScrollTop = st;
    }

    //Search button click.
    searchButton.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        menuButton.attr("aria-expanded", "false");
        primaryNavListItemExpand.attr("aria-expanded", "false");
        secondaryNavListItemExpand.attr("aria-expanded", "false");
        primaryNavList.removeClass("open");
        primaryNavListItem.removeClass("open");
        secondaryNavListItem.removeClass("open");
        searchContainer.toggleClass("open");

        if (searchContainer.hasClass("open")) {
            $(this).attr("aria-expanded", "true");
            setPositionTop(searchContainer, masthead);

            //Move focus into search input when search button clicked
            $(".search-input").focus();

            //This is for the coveo generated form input
            $("input[form='coveo-dummy-form']").focus();
        } else {
            $(this).attr("aria-expanded", "false");
            searchContainer.removeAttr("style");
        }
    });

    //Search container click/focus to offset document click that hides this and nav
    $(".search-input").on("focus click", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    //This is for the coveo generated form input
    $("input[form='coveo-dummy-form']").on("focus click", function (e) {
        console.log("got that focus!");
    });

    //Menu button click.
    menuButton.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        searchButton.attr("aria-expanded", "false");
        searchContainer.removeClass("open");
        primaryNavList.toggleClass("open");

        if (!primaryNavList.hasClass("open")) primaryNavListItem.removeClass("open");

        if (primaryNavList.hasClass("open")) {
            $(this).attr("aria-expanded", "true");
            setPositionTop(primaryNavList, masthead);
        } else {
            $(this).attr("aria-expanded", "false");
            primaryNavList.removeAttr("style");
        }
    });

    //Show hide secondary nav.
    $(".primary-nav-list-item > a:not(:last-child)").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        primaryNavListItem.not($(this).parent()).removeClass("open");
        secondaryNavListItem.removeClass("open");
        $(this).parent().toggleClass("open");

        //Call function to handle placement of secondary nav dropdown on desktop view
        secondaryNavPositioning($(this));
        if ($(this).parent().hasClass("open")) {
            $(this).attr("aria-expanded", "true");
            setPositionTop(secondaryNav, primaryNavList);
        } else {
            $(this).attr("aria-expanded", "false");
            secondaryNav.removeAttr("style");
        }
    });

    //Show hide tertiary nav.
    $(".tertiary-nav-list").prev("a").on("click", function (e) {
        if (!mQLargeAlt.matches) {
            e.stopPropagation();
            e.preventDefault();
            secondaryNavListItem.not($(this).parent()).removeClass("open");
            $(this).parent().toggleClass("open");
            $(this).parent().hasClass("open") ?
                $(this).attr("aria-expanded", "true")
                :
                $(this).attr("aria-expanded", "false")
        }
    });

    //Items I don't want clickable for document click
    $("#nav-container ul, #log-in-links ul").on("click", function (e) {
        e.stopPropagation();
    });

    //Close nav and search when clicking off
    $(document).on("click", function (e) {
        HideAllNav();
        hideSearchContainer();

        //Hide cart dropdown nav
        hide(cartMenuButton, primaryNavListCart);
    });

    //Function to hide/collapse search container
    function hideSearchContainer() {
        searchButton.attr("aria-expanded", "false");
        searchContainer.removeClass("open").removeAttr("style");
    }

    //Function to handle search container positioning
    function handleSearchContainer() {
        if (searchContainer.hasClass("open")) {
            setPositionTop(searchContainer, masthead);
        }

        if (window.matchMedia("(min-width: 1209px)").matches) {
            hideSearchContainer();
        }
    }


    //Adjustments when resizing
    $(window).on("resize", function () {
        var windowInnerWidth = $(window).innerWidth();
        var windowXCenter = windowInnerWidth / 2;
        var windowXOneFourth = windowXCenter / 2;
        var windowXThreeFourth = windowXCenter + windowXOneFourth

        //position or hide search container
        handleSearchContainer();

        //on window resize, run function to hide all nav
        HideAllNav();

        //on window resize, run function to add/remove aria attributes
        ariaAttributes();

        //set or remove main's margin-top
        //setMarginTop(isMobileWidth(), main);
        setMarginTopForLinkOrMain();

        //Hide cart dropdown nav
        hide(cartMenuButton, primaryNavListCart);
    });

    //Control placement of aria attribues
    function ariaAttributes() {
        primaryHasDropdown.attr("aria-expanded", false);
        primaryHasDropdown.attr("aria-haspopup", true);
        secondaryHasDropdown.attr("aria-expanded", false);
        secondaryHasDropdown.attr("aria-haspopup", true);

        //if the page is desktop view, remove the aria attribues from secondary nav list item anchor link so screen reader isn't announcing something that no longer applies
        if (mQLargeAlt.matches) {
            secondaryHasDropdown.removeAttr("aria-expanded aria-haspopup");
        }
    }

    //Hide all when esc key is pressed.
    $(document).keyup(function (e) {
        // escape key maps to keycode `27`
        if (e.keyCode == 27) {
            HideAllNav();

            //Hide cart dropdown nav
            hide(cartMenuButton, primaryNavListCart);
        }
    });

    //Move this function to a Common library (?)
    function findWords(str, count) {
        var i = 0;
        var prev = " ";
        while (count > 0 && i < str.length) {
            if (str.charAt(i) == " " && prev != " ") count--;

            prev = str.charAt(i);

            if (count == 0) return i;
            else i++;
        }

        return false;
    }

    //If the current page title is longer than 5 words, truncate it.
    $('.current-page').each(function () {
        var $this = $(this);
        var index = findWords($this.html(), 5);

        if (index > 0) {
            $this.html($this.html().substring(0, index)).append(
                '<span>. . .</span>'
            );
        }
    });
}());
