//////////////////////////////////////////////////////////////////////////
//JS for setting Pride logo every June
const logoMain = document.querySelector("#logo-main,#logoMain");
const logoMobile = document.querySelector("#logo-mobile,#logoMobile");
const logoNew = document.querySelector('masthead__branding-image');
const pridePinwheelLogoPath = '/Content/img/2x/logos/cfa-logo-wheel-pride.png';
const pridePinwheelTextLogoPath = '/Content/img/2x/logos/cfa-logo-pride.png';
const urlParams = new URLSearchParams(window.location.search);

//Function to get current date
function getCurrentDate() {
    return new Date();
}

//JS dates are zero-indexed so 5 = June (Pride month)
if (
    (
        getCurrentDate().getMonth() === 5 ||
        urlParams.get('qa-month') == 6
    ) &&
    (
        (
            logoMain &&  !logoMain.getAttribute('src').includes('pride')
        ) ||
        (
            logoMobile && !logoMobile.getAttribute('src').includes('pride')
        )
    )
) {
    if (logoMain) {
        const urlObject = new URL(logoMain.getAttribute('src'), window.location.origin);
        const hostParts = urlObject.host.split('.');

        // Logo is controlled by PL
        if (hostParts[0] === 'uxpatterns') {
            urlObject.pathname = pridePinwheelTextLogoPath;
            logoMain.setAttribute("src", urlObject.href);
        }
    }
    if (logoMobile) {
        const urlObject = new URL(logoMobile.getAttribute('src'),  window.location.origin);

        // Logo is controlled by PL
        const hostParts = urlObject.host.split('.');
        if (hostParts[0] === 'uxpatterns') {
            urlObject.pathname = pridePinwheelLogoPath;
            logoMobile.setAttribute("src", urlObject.href)
        }
    }
    if (logoNew) {
        logoNew.classList.remove('icon--logo');
        logoNew.classList.add('icon--logo-pride');
    }
}
//////////////////////////////////////////////////////////////////////////


/* Ensure all skip links work */
$('#skip-link').click(function (e) {
    e.preventDefault();
    if ($('#contentarea').length > 0) {
        console.log("content exists");
        $('#contentarea').attr('tabindex', '-1').focus();
    } else if ($('#main').length > 0) {
        console.log("main exists");
        $('#main').attr('tabindex', '-1').focus();
    }
});

//////////////////////////////////////////////////////////////////////////

/* Get file type inputs styled nicely */
var uploadElements = $("input[type=file]");
var removeElements = $(".remove");
if (uploadElements.length > 0) {
  uploadElements.addClass("js");
  uploadElements.change(function (e) {
      var dis = "";
      var files = this;

      if (this.files.length == 1) {
          dis = this.value.split('\\').pop();
      } else if (this.files.length > 1) {
          $.each(this.files, function (i) {
              if (i == 0) dis = files.files[i].name;
              else dis = dis + ", " + files.files[i].name;
          });
      }

      $(e.target).next().find(".selected-file").text(dis);
  });

  /* for PL example only
  if (removeElements.length > 0) {
      removeElements.click(function (e) {
          e.preventDefault();
          $(this).siblings("input").val(null);
          $(this).siblings("input").trigger("change");
      });
  }
  */
}

/* Change social links on search filter / hash change */
if ($(".sharing-card").length > 0) {
    $(window).bind('hashchange', function () {
        var pageURL = window.location.href;
        var pageTitle = document.title;

        $(".sharing-card a.fb-link").attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + pageURL);
        $(".sharing-card a.t-link").attr("href", "https://twitter.com/intent/tweet?url=" + pageURL);
        $(".sharing-card a.li-link").attr("href", "http://www.linkedin.com/shareArticle?mini=true&url=" + pageURL);

        var snLink = $(".sharing-card a.sn-link");
        if (snLink.length > 0) {
            var sn = snLink.attr("href").replace(/url=([^&]+)/, "url=" + pageURL).replace(/title=([^&]+)/, "title=" + pageTitle);
            snLink.attr("href", sn);
        }
        $(".sharing-card a.email-link").attr("href", "mailto:?subject=" + pageTitle + "&amp;body=" + pageURL);
    });
}

/*Initialize loading overlay.*/
if (typeof showLoadingOverlay !== 'undefined' && $.fn.LoadingOverlay !== undefined && showLoadingOverlay) {
    if (navigator.userAgent.match('MSIE 10.0;')) {
        $(document).ajaxStart(function () {
            $.LoadingOverlay('show', {
                image: "",
                progress: true
            });

        });
    } else {
        $(document).ajaxStart(function () {
            $.LoadingOverlay('show');
        });
    }

    $(document).ajaxStop(function () {
        $.LoadingOverlay('hide');
        //$('.field-group-message').remove();
    });
}

//Position global alerts when present; Make sure all of footer is visible even if GDPR or Global Alerts are present; Adjust spacing when GDPR and/or Global Alerts are dismissed.
var siteFooter = $(".site-footer");
var globalAlerts = $(".alert-global-information, .alert-global-warning");
var globalAlertPlaceholder = $(".global-alerts-placeholder");
var alertClose = $(".close, #closePrivacyWarning, #privacySave").not("#privacyClose");
var privacyWarning = $("#privacy-banner, #privacyWarning").not(":hidden");

//Check to see if the alerts are separated into their own container. If not, put them in one.
if (globalAlertPlaceholder.length == 0) {
    $("body").prepend("<section class='global-alerts-placeholder'></section>");
    globalAlertPlaceholder = $(".global-alerts-placeholder");
}
    
globalAlerts.each(function () {
    if ($(this).parent().not(".global-alerts-placeholder")) {
        $(this).appendTo(globalAlertPlaceholder);
    }
});

//On page load, fix global alert row, position it, and give it a z-index for visibility
globalAlertPlaceholder.css("position", "fixed").css("bottom", getPrivacyWarningHeight()).css("z-index", 5).css("margin-bottom", 0);

//On page load, if privacy warning is display: none (because the user navigated to another page without closing it out), set site footer's margin to zero (because it still calculates the privacy warning's height and sets that as the site footer's margin bottom). If the privacy warning is there, set the margin bottom of the site footer to be the height of the privacy warning.
siteFooter.css("margin-bottom", getMarginBottomNumber());

//When window is resized, recalculate global alert row and site footer position 
$(window).on("resize", function () {
    globalAlertPlaceholder.css("bottom", getPrivacyWarningHeight());
    siteFooter.css("margin-bottom", getMarginBottomNumber());
});

//Get privacy warning's height
function getPrivacyWarningHeight() {
    var privacyWarningHeightNumber = 0;

    if (privacyWarning.length > 0 && privacyWarning.is(":visible")) {
        var privacyWarningHeight = privacyWarning.outerHeight(true);
        privacyWarningHeightNumber = parseFloat(privacyWarningHeight);
    }

    return privacyWarningHeightNumber;
}

//Get global alert row's height using combined height of each global alert present inside of it
function getGlobalAlertPlaceholderHeight() {
    var totalHeight = 0;
    globalAlerts.each(function () {
        totalHeight += $(this)[0].offsetHeight;
    });
    return totalHeight;
}

//Get margin-bottom number to be used to reset site footer's position while alerts are present
function getMarginBottomNumber() {
    var totalMarginBottom = getPrivacyWarningHeight() + getGlobalAlertPlaceholderHeight();
    return totalMarginBottom;
}

//As alerts are closed/removed from DOM, reposition other alerts and reposition site footer
alertClose.on("click", function (e) {
    if(e.target.id == "closePrivacyWarning") {
        globalAlertPlaceholder.css("bottom", 0);
        siteFooter.css("margin-bottom", getGlobalAlertPlaceholderHeight());
    } else {
        $(this).each(function () {
            siteFooter.css("margin-bottom", getMarginBottomNumber());
        });
    }
});

//Creating hide event listener for privacy warning
privacyWarning.on("hideEvent", function () {
    globalAlertPlaceholder.css("bottom", "0");
    siteFooter.css("margin-bottom", getMarginBottomNumber());
});

var _oldhide = $.fn.hide;
$.fn.hide = function (speed, callback) {
    $(this).trigger('hideEvent');
    return _oldhide.apply(this, arguments);
}

//Select All functionality

//get the select all checkboxes 
var selectAllCb = $(".sa-checkbox");

//for each select all checkbox
selectAllCb.each(function () {
    //on change
    $(this).on("change", function () {
        //find the select all checkbox closest select all container and then find all the checkboxes inside that container
        var checkboxes = $(this).closest('.select-all-container').find(':checkbox');
        //check or uncheck all the checkboxes
        checkboxes.prop('checked', $(this).is(':checked'));
    });
});

//jQuery for tabs when select field is being used in mobile view
var selectField = $(".tab-group-tabs-form-select");
var selectOptions = $(".tab-group-tabs-form-select option");
var tabs = $(".tab-group-tabs-item-title a");

selectField.on('change', function (e) {
    var id = $(this).val();
    $('a[href="' + id + '"]').tab('show');

});

tabs.each(function () {
    $(this).on("click", function () {
        var tabHref = $(this).attr("href");
        var tabGroup = $(this).parents(".tab-group");
        var tabGroupTabsFormSelect = tabGroup.find(".tab-group-tabs-form-select");
        var optionsToFilter = tabGroupTabsFormSelect.children();

        optionsToFilter.each(function () {
            if (tabHref === $(this).val()) {
                tabGroupTabsFormSelect.val($(this).val());
                return false;
            } 
        });
    });
});

$(window).on("load", function () {
    selectField.each(function () {
        $(this).children("option").first().prop("selected", true);
    });
});

//Dynamic character limit label
function characterLimit() {
    $("[maxlength].max-length").on("input", function () {
        var $this = $(this);
        var max = parseInt($this.attr("maxlength"));
        var cur = $this.val().length;
        var tot = max - cur;
        var msg = tot + " characters left";
        if (tot == 1)
            msg = tot + " character left";

        $this.siblings(".max-length-counter").remove();

        if (cur == max) {
            $("<span>").addClass("error max-length-counter").append(msg).insertAfter($this);
        } else if (cur < max && cur >= max - 5) {
            $("<span>").addClass("max-length-counter").append(msg).insertAfter($this);
        }
    })

    .on("blur", function () {
        $(this).siblings(".max-length-counter").remove();
    });
}

characterLimit();

//Show hide toggle system

var $_tracked = $("[data-track]");

function toggleElements(selected, toggleElement) {
    if (selected) toggleElement.show();
    else toggleElement.hide();

}

$_tracked.on("change", function () {
    var visible = [];

    $("[data-show]").each(function () {
        var val = $(this).data("show").split(" ");
        var selected = this.selected || this.checked;

        $.each(val, function () {
            var id = this.toString();
            var toggleElement = $("#" + id);
            var inActive = visible.indexOf(id);

            if (inActive == -1 && selected) {
                visible.push(id);
            } else if (inActive > -1 && !selected) {
                return;
            }
            toggleElements(selected, toggleElement);
        });
    });
});


$_tracked.first().trigger("change");