(function () {
  var appNavToggle = $(".app-nav-toggle");
  var appNavPills = $(".app-nav-pills");
  var appNavPillsItem = $(".app-nav-pills > .nav-pills > li > a");

  function closeAll() {
    appNavToggle.removeClass("open");
    appNavPills.removeClass("open");
  }

  appNavToggle.on("click", function (event) {
    event.stopPropagation();
    if ($(window).width() < 992) {
      if (appNavToggle.hasClass("open")) {
        appNavToggle.removeClass("open");
        appNavPills.removeClass("open");
        appNavToggle.attr('aria-expanded', 'false');
        appNavPills.attr('aria-expanded', 'false');
      } else {
        appNavToggle.addClass("open");
        appNavPills.addClass("open");
        appNavToggle.attr('aria-expanded', 'true');
        appNavPills.attr('aria-expanded', 'true');
      }
    }
  });

  appNavPillsItem.each(function () {
    $(this).on("click", function (e) {
      e.stopPropagation();
    });
  });

  $(document).on("click", function () {
    closeAll();
  });

  $(window).resize(function () {
    closeAll();
  });
})();
