function getDomains() {
    if (window.location.hostname.toLowerCase().includes("localhost")) {
        return [
            "localhost",
        ];
    }
    return [
        ".arx.cfa", 
        ".cfainstitute.org",
    ];
}

//Function to set cookie
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();

    for (const domain of getDomains()) {
        document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";secure;path=/";
    }
}

//Function to get cookie
function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    let str = "";
    for (let i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            str = c.substring(name.length, c.length);
            break;
        }
    }
    return str;
}

const $privacyBanner = $("#privacy-banner");
const $closePrivacyWarning = $("#closePrivacyWarning");

$closePrivacyWarning.click(function () {
    setCookie("cfa_gdpr_tracking", "1", 1);
    $privacyBanner.hide();
});

const previousOptIn = getCookie("cfa_gdpr_tracking");

if (previousOptIn) {
    $privacyBanner.hide();
}

const url = location.protocol + "//" + location.host + location.pathname;
const frf = getCookie("FindRefreshFilter");
const isManual = getCookie("cfa_ismanual_gdpr");
let track = getCookie("cfa_gdpr_tracking");

//Fresh page load set cookies
if (frf == "" && track == "") {
    setCookie("FindRefreshFilter", url, 1);
    setCookie("cfa_gdpr_tracking", "0", 1);
    //Switching page without clicking modal, opt in
} else if (
    frf != url && track == "0" && isManual == "" ||
    // Soft opt-in when logged in if still in implicit flow
    (!isManual && window.digitalData && window.digitalData.user)
) {
    setCookie("cfa_gdpr_tracking", "1", 1);
    track = "1";
  //Refreshing page with no other actions, no change
} else if (frf == url && track == "0" && isManual == "") {
    track = "";
}

if (track != "") $privacyBanner.hide();
